// $primary: #a51616;
$primary: rgba(50, 80, 100, 1); /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
$tertiary: #e4f0ff;
$scale: 1.25;
$boxWidth: 48;

@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Open+Sans');
p {
	font-family: 'Open Sans', sans-serif;
}
h1,h2,h3 {
	font-family: 'Alfa Slab One', cursive;
	color: $primary;
}
.flash {
	display:none;
}

nav {
	z-index: 99;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		color: #fff !important;
		margin-top: 20px;
		border-radius: 5px;

		@media (max-width: 767px) {
			margin-top: 0;
		}

	    &:hover {
	    	background: rgba(51, 122, 183, 0.75);
	    	color: #eee !important;
	    	border-radius: 5px;
	    }

	   	&:visited,&:focus,&:active {
		  color: #fff;
		  outline: 0;
	    }
	}
}
.navbar-collapse {
	@media (max-width: 767px) {
		background: rgba(0, 0, 0, 0.75);
	}
}
.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 14px;
		background: #5487c6;

		&.navbar-toggle .icon-bar {
		    background-color: #fff;
		}

	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	position: relative;
	z-index: 999;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 50%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: $primary;
	color: $footerLinks;
	text-decoration: none;
	outline: 0;

	a {
		color: $footerLinks;
		text-decoration: none;

	&:hover,&:focus,&:visited,&:active {
	    	color: $footerLinks;
	    	text-decoration: none;
	    	outline: 0;
	    }
	}
}


input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
.my-slider img {
	width: 100%;
}


nav.navbar-default {
	background: transparent;
	border: none;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999;
	transition: all 0.9s ease-in-out;

}
nav.navbar {

	&.navSolid { 
	background: $primary;
	
	li > a {
		color: black;
		@media (max-width: 767px) {
			color: #5487c6;
		}
	}
	
	}

}
.unslider-arrow {
	@media (max-width: 600px) {
		display: none;
	}
}


.logo {
	transition: all 0.5s ease-in-out;
	width: 100%;
	padding: 10px;
	max-width: 250px;


	@media (max-width: 767px) {
		max-width: 150px;
	}
}

.row {
	padding: 0;
	margin: 0;
}

.boxContain {
	z-index: 2;
	max-width: $boxWidth * 1%;
	margin: 0 auto;
	z-index: 2;
	transform: scale($scale);
	@media (max-width: 991px) {
		transform: scale($scale * 0.9);
	}
	@media (max-width: 767px) {
		max-width: $boxWidth * 1.7 * 1%;
		transform: scale($scale * 0.8);
	}	
}

.boxCol {
	padding: 0;
	margin: 0;
	h1 {
		font-size: 1.2em;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			width: 50%;
			left: 50%;
			transform: translateX(-50%);
			border-bottom: 1px solid #eee;
			bottom: 0;
		}
	}
	p {
		font-size: 0.8em;
		padding: 2em;
	}
}

.boxImg1 {
	background: url(../img/boxImg1.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	background-position: 50% 20%;
}
.boxImg2 {
	background: url(../img/boxImg2.jpg) no-repeat;
	background-size: cover;
	height: 250px;
	background-position: 0% 50%;
}

.box {
  background:#d8d8d8;
  position: relative;
  margin: auto;
  background: white;
  z-index: 0;
  box-shadow: 0px  0px 5px #eee;	
}
.bgColor {
	background: #f8f8f8;
}
.bg {
	// background: url(../img/banner4.jpg) no-repeat;
	background: linear-gradient(rgba(0, 0, 0, 0.8),rgba(50, 80, 100, 0.8)),url('../img/banner4.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 65% 50%;
	height: 600px;
	z-index: -1;
	background-attachment: fixed;
	position: relative;
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
	@media (max-width: 675px) {
		height: 500px;
			background-position: 25% 50%;
	}
	h1 {
		top: 50%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -50%);
		position: absolute;
		padding: 0;
		margin: 0;
		color: white;
		font-size: 4em;
		@media (max-width: 675px) {
		font-size: 2em;
		}
	}
}
.bg2 {
	// background: url(../img/banner5.jpg) no-repeat;
	background: linear-gradient(rgba(50, 80, 100, 0.8),rgba(50, 80, 100, 0.8)),url('../img/banner5.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 65% 50%;
	height: 600px;
	background-attachment: fixed;
	position: relative;
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
	@media (max-width: 767px) {
	    background-position: 65% 50%;
		height: auto;
		padding: 100px 0;
	}
	h1 {
		top: 101%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -101%);
		position: absolute;
		color: white;
		font-size: 6em;
		@media (max-width: 991px) {
			display: none;
		}
	}
	p {
		top: 50%;
		left: 50%;
		font-size: 1.5em;
		transform: translate(-50%, -50%);
		position: absolute;
		text-align: center;
		color: white;
		@media (max-width: 767px) {
			font-size: 1.25em;
			position: static;
			transform: none;
		}
		@media (max-width: 500px) {
			font-size: 1.1em;
		}

	}
}

.my-slider li {
	position: relative;
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		font-size: 3.5em;
		text-shadow: 2px 2px black;
		@media (max-width: 600px) {
			font-size: 2em;
		}
		@media (max-width: 500px) {
			display: none;
		}
	}
}

.formBox {
	h1 {
		font-size: 2em;
	}
	p {
		font-size: 1.5em;
	}

	@media (max-width: 500px) {
		p {
			font-size: 1em;
		}
	}

}

.unslider {
	position: relative;
}

/* Calculate margin-top for div below .boxContain */
// @function calcMT($value1, $value2, $value3) {
// 	@return $value1 * $value2 * $value3;
// }
// .margTop {
// 	margin-top: calcMT($boxWidth, $scale, 1.5px);
// 	@media (max-width: 991px) {
// 		margin-top: calcMT($boxWidth, $scale, 2px);
// 	}
// }


.unslider {
    /*overflow: auto;*/
    margin: 0;
    padding: 0;
}

.unslider-wrap {
    position: relative
}

.unslider-wrap.unslider-carousel>li {
    float: left
}

.unslider-vertical>ul {
    height: 100%
}

.unslider-wrap li img {
    position: relative;
}
.unslider-vertical li {
    float: none;
    width: 100%
}

.unslider-fade {
    position: relative
}

.unslider-fade .unslider-wrap li {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 8
}

.unslider-fade .unslider-wrap li.unslider-active {
    z-index: 10
}

.unslider li,
.unslider ol,
.unslider ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none
}

.unslider-arrow {
    position: absolute;
    left: 20px;
    z-index: 2;
    cursor: pointer;
}

.unslider-arrow.next {
    left: auto;
    right: 20px;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: url(../img/arrow.svg);
}

.unslider-arrow.prev {
    left: 20px;
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    background: url(../img/arrow.svg);
}


.unslider-arrow.next, .unslider-arrow.prev {
    @media (max-width: 767px) {
        width: 25px;
        height: 25px;
    }
}

.top-pad {
	margin-top: 100px;
}

.tech {
  background: #325064 !important;
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
  margin-right: 0px !important;
}